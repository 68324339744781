import React, { Component } from 'react'
import './App.scss';
import Home from './components/Screens/Home2'
import Music from './components/Screens2/ArtistsHome'
import BookMe from './components/Screens/BookMe'
import Events from './components/Screens/Events'
import ArtistsHome from './components/Screens2/ArtistsHome'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import NavBar from "./components/Header/NavBar";

 class App extends Component {
  componentWillMount () {
    window.addEventListener("resize", window.resizeTo(window.outerWidth/0.75, window.outerHeight/0.75))
  }
render() {
    return (
      <div>
        <div className="App">
      
      <BrowserRouter>
    
     <NavBar/>
    
    <Switch>
     <Route exact path='/' component={Home}/>
     <Route exact path='/portofolio' component={ArtistsHome}/>
     <Route exact path='/music' component={Music}/>
     <Route exact path='/bookme' component={BookMe}/>
     <Route exact path='/events' component={Events}/>
     </Switch>
   
     </BrowserRouter>
   </div>
      </div>
    )
  }
}

export default App

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { createStore } from 'redux'
import rootReducer from './components/reducers/projectReducer'
import {PersistGate} from 'redux-persist/integration/react' 
import {persistStore, persistReducer} from 'redux-persist'
import { Provider } from 'react-redux'
import storage from 'redux-persist/lib/storage'
//import { composeWithDevTools } from 'redux-devtools-extension';

import * as firebase from 'firebase' 
import { unregister } from './registerServiceWorker';

/*var firebaseConfig = {
    apiKey: "AIzaSyCtbuRVL6Ao0QorUS9RueB_ZyI2YcmSdxc",
    authDomain: "nadiamukamimusic.firebaseapp.com",
    databaseURL: "https://nadiamukamimusic.firebaseio.com",
    projectId: "nadiamukamimusic",
    storageBucket: "nadiamukamimusic.appspot.com",
    messagingSenderId: "579426974665",
    appId: "1:579426974665:web:6245a6008e8efa272d0618"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig); */

const config = {
  apiKey: "AIzaSyATcsY9JcilkadINUSdipNI6ESZ-6D7cfI",
  authDomain: "seroneymusik.firebaseapp.com",
  databaseURL: "https://seroneymusik.firebaseio.com",
  projectId: "seroneymusik",
  storageBucket: "seroneymusik.appspot.com",
  messagingSenderId: "1091439979435",
  appId: "1:1091439979435:web:42a3c55be8996d48eb40ee"
};
firebase.initializeApp(config);

const persistConfig={
    key:'root',
    storage
}

const persistedReducer=persistReducer(persistConfig, rootReducer)


const store = createStore(persistedReducer);
const persistor=persistStore(store)
const rootElement=document.getElementById('root');

ReactDOM.render(<Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <App />
    </PersistGate>
    </Provider>,
    rootElement );


//registerServiceWorker();
unregister()
import React, { Component } from 'react'
import style from './Home6.module.scss';//FaPlay
import {FaPlay,FaPlayCircle} from 'react-icons/fa';
import {Link, NavLink} from 'react-router-dom'
const music=[
    {id:1, title:'A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'./pic14.jpg'},
    {id:2, title:'A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'./pic12.jpg'},
    {id:3, title:'A STAR ALBUM PHOTO SHOOT',location:'NAIROBI, KENYA', time:'22 NOV', image:'./pic13.jpg'},
    {id:3, title:'A STAR ALBUM PHOTO SHOOT',location:'NAIROBI, KENYA', time:'22 NOV', image:'./pic13.jpg'}
]

 class Home6 extends Component {
    render() {
        const musicList=music.slice(0,3).map(item=>{
            let theTime=item.time.split(' ')
            let month=theTime[1]
            let date=theTime[0]
            return(
                <div className={style.listCont} style={{backgroundImage:"url(" + item.image + ")"}}>
                    <div className={style.listCont2}>
                     <div className={style.dateCont}>
                         <h1>{date}</h1>
                         <p>{month}</p>
                     </div>
                     <div className={style.placeCont}>
                         <h1>{item.title}</h1>
                        <p>{item.location}</p>
                     </div>
                     <div className={style.footerCont}>
                     <div className={style.footerCont2}>

                      </div>
                     </div>
                    </div>
               </div>
            )
        })
        return (
            <div className={style.container}>
                <div className={style.contHead}>
                    <h1>GALLERY</h1>
                    <NavLink to='/events' style={{color:'#000', textDecoration:'none'}}><h1>SEE ALL</h1></NavLink>
                </div>
                <div className={style.contBody}>
                  {musicList}
                </div>
                  
            </div>
        )
    }
}

export default Home6

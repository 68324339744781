import React, { Component } from 'react'
import './NavBar.scss';
import {Link, NavLink} from 'react-router-dom'
import { FaBars} from 'react-icons/fa';
import { MdKeyboardArrowRight,MdClear} from 'react-icons/md';



class Navbar extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             iconToShow:false
            
        }
}
onScroll = () => { 
    const isTop=window.scrollY>100;
    const nav=document.getElementById('nav');
    if(isTop){
        nav.classList.add("scrolled");
    }else{
        nav.classList.remove("scrolled");
    }
}

componentDidMount(){
    window.addEventListener("scroll", this.onScroll, false);
}
componentWillUnmount(){
    window.removeEventListener("scroll", this.onScroll, false);
}
openOptions=()=>{
  this.setState({
    iconToShow:true
  })
}
closeOptions=()=>{
    this.setState({
        iconToShow:false
      })
}


    render() {
        let iconToShow=''
        let iconStatus=this.state.iconToShow
        if(iconStatus===false){
            iconToShow=<FaBars className='openOptions' onClick={()=>this.openOptions()}/>
        }else if(iconStatus===true){
            iconToShow=<MdClear className='closeOptions' onClick={()=>this.closeOptions()}/>
        }
        
        return (
            <div  id="nav" className="nav">
                <div className="navCont">
               
                <NavLink to='/' className='ulLink'><img className='navImg' src={'./LOGO.png'} /> {/*<h1>SERONEYMUSIC</h1>*/}</NavLink>
                <ul className='linkUl'>
                <NavLink to='/' className='ulLink'><li>HOME</li></NavLink>
               
                <NavLink to='/music' className='ulLink'><li>MUSIC</li></NavLink>
                <NavLink to='/events' className='ulLink'><li>GALLERY</li></NavLink>
                <NavLink to='/bookme' className='ulLink'><li>BOOKING</li></NavLink>
             
                
                </ul>
                {iconToShow}
                </div>
                {iconStatus?
                <div className="navCont2">
                <ul className='linkUl2'>
                 <NavLink to='/' className='ulLink'><div className='menuItems'  onClick={()=>this.closeOptions()}><li>HOME</li>
                <MdKeyboardArrowRight className='menuArrow'/></div></NavLink> 
                <NavLink to='/music' className='ulLink'><div className='menuItems'  onClick={()=>this.closeOptions()}> <li>MUSIC</li>
                <MdKeyboardArrowRight className='menuArrow'/></div></NavLink>
                <NavLink to='/events' className='ulLink'><div className='menuItems'  onClick={()=>this.closeOptions()}><li>GALLERY</li>
                <MdKeyboardArrowRight className='menuArrow'/></div></NavLink> 
                <NavLink to='/bookme' className='ulLink'><div className='menuItems'  onClick={()=>this.closeOptions()}> <li>BOOKING</li>
                <MdKeyboardArrowRight className='menuArrow'/></div></NavLink> 
                
                    
                </ul>
                </div>:null}
            </div>
        )
    }
}

export default Navbar


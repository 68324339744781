import React, { Component } from 'react'
import style from './Album.module.scss';
import firebase from 'firebase'
let photo="./pic11.jpg"
let photo2="./pic26.jpg"
const theSongs=[
  {id:1, title:'Summertime',artist:'Seroney',time:'03:14'},
  {id:2, title:"Party Don't Stop",artist:'Seroney',time:'02:36'},
  {id:3, title:'Wiggle',artist:'Seroney FT. Masauti & Exray',time:'03:57'},
  {id:4, title:'Nakutamani',artist:'Seroney',time:'02:06'},
  {id:5, title:'Fiesta',artist:'Seroney FT. Kidis',time:'03:20'},
  {id:6, title:"Whine It",artist:'Seroney',time:'02:50'},
  {id:7, title:"Nobody",artist:'Seroney',time:'02:56'},
  {id:8, title:'Pick Up The Phone',artist:'Seroney FT. Drill',time:'03:18'},
  {id:9, title:'Banana',artist:'Seroney FT. Bryon & Drill',time:'02:45'},
  {id:10,title:'Am A Star',artist:'Seroney',time:'04:00'},
]
class Albums extends Component {
  state={
    musicArray:[]
  }
  componentDidMount(){
    this.fetchMusic()
  }
  fetchMusic=async()=>{
    try {
      var newArray=[]
      var newArray2 = []
      var musicRef = firebase.database().ref('/Albums/NADIA-MUKAMI/AfricanPopstar');

      await musicRef.once('value', (snapshot) => {
        snapshot.forEach((data) => {
         var id = data.key
         var artist= data.val().name
         var duration= data.val().duration
         var music= data.val().audio
         var songname= data.val().title
         var released= data.val().released
         newArray={
            artist:artist, duration:duration, music:music, songname:songname, id:id, released:released
              }
              newArray2.push(newArray)
              })
              this.setState({
                musicArray:newArray2
        })
       })
       console.log('musicArray', this.state.musicArray)
    } catch (error) {
      
    }
  }
  goToExtenalLink=()=>{
    window.open('https://umgke.lnk.to/AmAStar','_blank')
  }
    render() {
     const musicList=theSongs.map((item,index)=>{
        return(
           <div className={style.divList} key={index} onClick={()=>this.goToExtenalLink()}>
             <div className={style.divImg}>
             <img  src={photo2} alt="Photo"></img>
             </div>
           <p className={style.artName}>{item.artist}</p>
           <p className={style.songDet}>{item.title}</p>
        <p className={style.songDur}>{item.time}</p>
           </div>
        )
      })
        return (
            <div className={style.container}>
              <div className={style.albumDetails}>
                <div className={style.albumArtWork}>
                <img className={style.albumImg} src={photo}></img>
                </div>
                <div className={style.albumDet}>
                  <h2>ABOUT ALBUM</h2>
                   <p className={style.title}>'AM A STAR' is an Album by Seroney. AM A STAR 
Album is an all-collaboration project with ten tracks including Summertime,
Party Don't Stop, Wiggle Featuring Masauti & Exray, Nakutamani,
Fiesta Featuring Kidis, Whine It, Nobody, 
Pick Up The Phone Featuring Drill Kawasaki
Banana Featuring Bryon & Drill Kawasaki and Am A Star 
</p>
                   <p className={style.titleP}><span className={style.title1}>Artists:</span><span className={style.title2}>Seroney</span></p>
                   <p className={style.titleP}><span className={style.title1}>Label:</span><span className={style.title2}>Universal Music Group</span></p>
                   <p className={style.titleP}><span className={style.title1}>Release Date:</span><span className={style.title2}>24th Nov 2022</span></p>
                   <p className={style.titleP}><span className={style.title1}>Genres:</span><span className={style.title2}>Afro Pop/Dancehall</span></p>
                   <p className={style.titleP}><span className={style.title1}>Publisher:</span><span className={style.title2}>Universal Music Group</span></p>
                   <h4 id={style.availTxt}>AVAILABLE ON</h4>
                   <div className={style.albumAvail}>
                   <a target="_blank"  href='https://umgke.lnk.to/AmAStar'>ITUNES</a>
                   <a target="_blank"  href='https://umgke.lnk.to/AmAStar'>BOOMPLAY</a>
                   <a target="_blank"  href='https://umgke.lnk.to/AmAStar'>SPOTIFY</a>
                   <a target="_blank"  href='https://umgke.lnk.to/AmAStar'>AUDIOMACK</a>
                   <a target="_blank"  href='https://umgke.lnk.to/AmAStar'>DEEZER</a>
                   </div>
                  </div>

                </div>
                <h3 className={style.songsH3}>TRACKLIST</h3>
                <div className={style.listCont}> {musicList}</div>
               
           </div>
        )
    }
}

export default Albums

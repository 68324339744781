import React, { Component } from 'react'
import style from './Home2.module.scss';
import Home from "./Reel";
import Home3 from "./Home3";
import Home4 from "./Home4";
import Home6 from "./Home6";
import Home7 from "./Home7";
import Videos from '../Screens2/Videos2'
import Footer from "../Footer/Footer";

class Home2 extends Component {
  componentDidMount(){
    window.scrollTo(0, 0)
   }
  render() {
    return (
      <div className={style.container}> 
        
         <div className={style.reelCont}> 
         <Home/>
         </div>
         <Home3/>
         <Home4/>
         <div className={style.vidCont}> 
         <h1>VIDEOS</h1>
         <Videos vidCount={3}/>
         </div>
         <Home7/>
         <Home6/>
         <Footer/>
      </div>
    )
  }
}

export default Home2

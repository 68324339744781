import React, { Component } from 'react'
import style from './Reel.module.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FaAngleLeft,FaAngleRight } from 'react-icons/fa';
import {Link, NavLink} from 'react-router-dom'



const theReel=[
    //{id:1, title:'Together, We Will Move', color:'red', details:'Kenya Forward', det2:'Azimio La Umoja. Inawezekana', image:'./c1.png'},
    {id:1, title:'Album Launch',  color:'black', details:'AM A STAR ALBUM',det3:'OUT NOW',det2:'The Music Kitchen', image:'./pic4.jpg'},
    {id:2, title:'More Music',  color:'black', details:'LISTEN TO MORE',det3:'OF MY MUSIC',det2:'Quality beats', image:'./PIC9.jpg'},
    {id:3, title:'Perfomances',  color:'black', details:'COME WATCH MY',det3:'PERFOMANCES',det2:'Music is our business', image:'./pic8.jpg'},
  ]
   
class Reel extends Component {
  state={
    theAdsArray:[],email:''
  }
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
      }
      componentDidMount(){
        window.scrollTo(0, 0)
        
      }
      inputChange(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({email:event.target.value});
      }
     /* fetchAdsInfo=async()=>{
       
        try {
          const theRef = firebase.database().ref('/reel/');
          var adsArray=[]
          var adsArray2=[]
         await theRef.once('value', (snapshot) => {
           snapshot.forEach((data) => {
            const id = data.key
            const title = data.val().title
            const details= data.val().details
            const image = data.val().image
            const color = data.val().color
            adsArray={
              title: title, details:details, image:image, id:id,color:color
            }
             
            adsArray2.push(adsArray)
            
           })
          })
          this.setState({
            theAdsArray:adsArray2
           })
          console.log('adsArray', this.state.adsArray)
        } catch (error) {
          
        }
      }*/

      next() {
        this.slider.slickNext();
      }
      previous() {
        this.slider.slickPrev();
      }
      scrollToPos=()=>{
      window.scrollTo(0, 550)
      }

 
    render() {
         let donateStyle=''
         let reelTextStyle=''
         let titleStyle=''
        const settings = { 
            /*infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            className:"slides",
            fade: true,
            autoplay: true,
            pauseOnHover: false,
      speed: 700,
      autoplaySpeed: 5000,
      cssEase: "linear"*/
      infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          className:"slides",
          autoplay: true,
          pauseOnHover: false,
          speed: 400,
          autoplaySpeed: 5000,
          cssEase: "linear",
      
          };
        return (
            <div className={style.boduTitleMainCont}>
                <Slider ref={c => (this.slider = c)} {...settings}>
                  
                {theReel.map((item)=>{
                  if(item.color!='black'){
                    donateStyle={
                      backgroundColor:'#174905'
                    }
                    reelTextStyle={
                      color:'#fff'
                    }
                    titleStyle={
                      color:'#fff'
                    }
                  }else{
                    donateStyle={
                     backgroundColor:'#174905'
                    }
                    reelTextStyle={
                      color:'#fff'
                    }
                    titleStyle={
                      color:'#fff'
                    }
                  }
                 return(
                       <div  className={style.titleDivCont} key={item.id}>
                       <div className={style.titleCont}>
                         <div className={style.titleCont2}>
                         <h4 className={style.titleH4} id={style.titleH4} style={titleStyle}>{item.title}</h4>  
                         <p className={style.detailsP} style={reelTextStyle}>{item.details} </p>
                         <p className={style.detailsP} style={reelTextStyle}>{item.det3} </p>
                         </div>
                       </div>
                     <img className={style.theImage} src={item.image}/>
                     
                    
                     </div>

                 )})}

                </Slider>
                 <div className={style.emDiv}>
                 <p className={style.det3}>Click below check more of our works</p>
                         <div className={style.emailDiv}>
                           {/*<input placeholder='Email address' id={style.email1} onChange={(event)=>this.inputChange(event)}></input>*/}
                           <NavLink to='/music' className={style.email2}>BROWSE MY MUSIC</NavLink>
                         </div>
                 </div>
               
            </div>
        )
    }
}
export default Reel

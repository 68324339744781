import React, { Component } from 'react'
import style from './Home3.module.scss';
let photo1="https://images.pexels.com/photos/343683/pexels-photo-343683.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
let photo="./pic11.jpg"

class Home3 extends Component {
    render() {
        return (
            <div className={style.container}>
                <h1 className={style.contTitle}>ALBUMS AND EPS</h1>
                 <div className={style.container1}>
                    <div className={style.contImg}>
                    <img className={style.artImg} src={photo}></img>
                    </div>
                </div>
                <div className={style.container2}>
                  <h2>GET MY NEW ALBUM</h2>
                  <h2>"AM A STAR" NOW</h2>
                  <p>'AM A STAR' is an Album by Seroney. AM A STAR 
Album is an all-collaboration project with ten tracks including Summertime,
Party Don't Stop, Wiggle Featuring Masauti & Exray, Nakutamani,
Fiesta Featuring Kidis, Whine It, Nobody, 
Pick Up The Phone Featuring Drill Kawasaki
Banana Featuring Bryon & Drill Kawasaki and Am A Star</p>
                  <div className={style.logosDiv}>
                 <a target="_blank"  href='https://umgke.lnk.to/AmAStar'><img className={style.boomImg} src={'/boomplay.png'}></img></a> 
                 <a target="_blank"  href='https://umgke.lnk.to/AmAStar'><img className={style.googleImg} src={'/spotify.png'}></img></a>
                 <a target="_blank"  href='https://umgke.lnk.to/AmAStar'><img className={style.appImg} src={'/apple2.png'}></img></a>
                  </div>
                </div>
            </div>
        )
    }
}

export default Home3

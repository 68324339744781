import React, { Component } from 'react'
import style from './Events.module.scss';//FaPlay
import Footer from '../Footer/Footer'
const music=[
    {id:1, title:'AM A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'https://firebasestorage.googleapis.com/v0/b/seroneymusik.appspot.com/o/theImages%2Fs1.jpg?alt=media&token=cee7837d-1396-41a0-afc6-96a72ca7050e'},
    {id:2, title:'AM A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'https://firebasestorage.googleapis.com/v0/b/seroneymusik.appspot.com/o/theImages%2Fs5.jpg?alt=media&token=eaa21dfa-825e-42cf-b584-e1f47023e851'},
    {id:3, title:'AM A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'https://firebasestorage.googleapis.com/v0/b/seroneymusik.appspot.com/o/theImages%2Fs16.jpg?alt=media&token=dc17aec2-3519-4fbd-bf37-a304c298c9db'},
    {id:4, title:'AM A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'https://firebasestorage.googleapis.com/v0/b/seroneymusik.appspot.com/o/theImages%2Fs3.jpg?alt=media&token=913d62d2-6295-4487-bd42-1f49f850e430'},
    {id:5, title:'AM A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'https://firebasestorage.googleapis.com/v0/b/seroneymusik.appspot.com/o/theImages%2Fs13.jpg?alt=media&token=7730d220-c94c-423f-ba4a-ab20b049a488'},
    {id:6, title:'AM A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'https://firebasestorage.googleapis.com/v0/b/seroneymusik.appspot.com/o/theImages%2Fs13.jpg?alt=media&token=7730d220-c94c-423f-ba4a-ab20b049a488'},
    {id:7, title:'AM A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'https://firebasestorage.googleapis.com/v0/b/seroneymusik.appspot.com/o/theImages%2FS21.jpg?alt=media&token=d89e78fe-af99-4d8b-832b-d64d25345234'},
    {id:8, title:'AM A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'https://firebasestorage.googleapis.com/v0/b/seroneymusik.appspot.com/o/theImages%2FS20.jpg?alt=media&token=f4800375-b65b-42da-9057-897d1d163bf0'},
    {id:9, title:'AM A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'https://firebasestorage.googleapis.com/v0/b/seroneymusik.appspot.com/o/theImages%2Fs14.jpg?alt=media&token=ccfe8bd3-c9df-45a7-b366-5b1dcbb4208d'},
    {id:10, title:'AM A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'https://firebasestorage.googleapis.com/v0/b/seroneymusik.appspot.com/o/theImages%2Fs2.jpg?alt=media&token=0d70e2b0-cd46-487a-9e09-224dbe7db007'},
    {id:11, title:'AM A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'https://firebasestorage.googleapis.com/v0/b/seroneymusik.appspot.com/o/theImages%2Fs7.jpg?alt=media&token=1b709bf0-bba6-43ec-81e7-1f5559d5607b'},
    {id:12, title:'AM A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'https://firebasestorage.googleapis.com/v0/b/seroneymusik.appspot.com/o/theImages%2FS22.jpg?alt=media&token=57896b77-6dd6-41c2-9d8d-8d24d4f2a510'},
    {id:13, title:'AM A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'https://firebasestorage.googleapis.com/v0/b/seroneymusik.appspot.com/o/theImages%2FS17.jpg?alt=media&token=a33d6cfa-3589-4c92-a41f-8506bbd36684'},
    {id:14, title:'AM A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'https://firebasestorage.googleapis.com/v0/b/seroneymusik.appspot.com/o/theImages%2Fs4.jpg?alt=media&token=b4e23e5c-c5e5-433e-9d8e-a8d776443c7d'},
    {id:15, title:'AM A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'https://firebasestorage.googleapis.com/v0/b/seroneymusik.appspot.com/o/theImages%2Fs8.jpg?alt=media&token=ce660f57-a4b4-4ee1-8473-5fe775b1c567'},
    {id:16, title:'AM A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'https://firebasestorage.googleapis.com/v0/b/seroneymusik.appspot.com/o/theImages%2FS21.jpg?alt=media&token=d89e78fe-af99-4d8b-832b-d64d25345234'},
    {id:17, title:'AM A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'https://firebasestorage.googleapis.com/v0/b/seroneymusik.appspot.com/o/theImages%2Fs9.jpg?alt=media&token=18b23d23-49c8-4043-a5cd-56860e73700f'},
    {id:18, title:'AM A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'https://firebasestorage.googleapis.com/v0/b/seroneymusik.appspot.com/o/theImages%2FS19.jpg?alt=media&token=083a5eab-b834-40d2-9684-32022c56654b'},
    {id:19, title:'AM A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'https://firebasestorage.googleapis.com/v0/b/seroneymusik.appspot.com/o/theImages%2Fs11.jpg?alt=media&token=7d2e567a-26c4-4936-ab4c-add1afa8539c'},
    {id:20, title:'AM A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'https://firebasestorage.googleapis.com/v0/b/seroneymusik.appspot.com/o/theImages%2Fs10.jpg?alt=media&token=96c0f8d1-7136-46f9-9376-b80316222315'},
    {id:21, title:'AM A STAR ALBUM PHOTO SHOOT', location:'NAIROBI, KENYA', time:'22 NOV', image:'https://firebasestorage.googleapis.com/v0/b/seroneymusik.appspot.com/o/theImages%2Fs12.jpg?alt=media&token=7fc7d2d2-6564-469c-b496-110e0ea9e706'}
]

 class Events extends Component {
    componentDidMount=async()=>{
        window.scrollTo(0, 0)}
    render() {
        const musicList=music.map(item=>{
            let theTime=item.time.split(' ')
            let month=theTime[1]
            let date=theTime[0]
            return(
               
                
                <div className={style.listCont} style={{backgroundImage:"url(" + item.image + ")"}}>
                    <div className={style.listCont2}>
                     <div className={style.dateCont}>
                         <h1>{date}</h1>
                         <p>{month}</p>
                     </div>
                     <div className={style.placeCont}>
                         <h1>{item.title}</h1>
                        <p>{item.location}</p>
                     </div>
                     <div className={style.footerCont}>
                     <div className={style.footerCont2}>

                      </div>
                     </div>
                    </div>
               </div>

               
            )
        })
        return (
            <div className={style.container}>
                <div className={style.contNav}>
                   
                </div>
                <div className={style.contHead}>
                    <h1>GALLERY</h1>
                </div>
                <div className={style.contBody}>
                  {musicList}
                </div>
                  <Footer/>
            </div>
        )
    }
}

export default Events

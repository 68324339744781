import React, { Component } from 'react'
import style from './ArtistsHome.module.scss';
import {TiTick} from 'react-icons/ti';
import Videos from './Videos2'
import Albums from './Albums'
const artists=[{name:'SERONEY'}]
const theImage='./pic13.jpg'
const theImage2='./pic15.jpg'
class ArtistsHome extends Component {
    state={
        itemsToShow:'videos',
        artistToShow:'SERONEY'
    }
    componentDidMount(){
        window.scrollTo(0, 0)
       }
    divToShow=async(event,name)=>{
      this.setState({
         itemsToShow:name
      })
     }
     changeLayout=(name)=>{
       this.setState({artistToShow:name})
     }
    render() {
        let artist=this.state.artistToShow
        let divToShow=''
        let status=this.state.itemsToShow
        let videosCont=[], imagesCont=[], eventsCont=[], albumsCont=[]
        let activeStyle=[]
        if (status==='videos') {videosCont={backgroundColor:'#000',  color:'#fff'}; divToShow=<Videos artist={artist} vidCount={300}/>} else {videosCont={}}
        if (status==='albums') {albumsCont={backgroundColor:'#000',  color:'#fff'}; divToShow=<Albums artist={artist}/>} else {albumsCont={}}
       
        const artistsList=artists.map(item=>{
            if (artist===item.name) {activeStyle={backgroundColor:'#000', color:'#fff'}}else{activeStyle={backgroundColor:'#fff', color:'#000'}}
         return(
           <div className={style.tickDiv} style={activeStyle} key={item.name} onClick={()=>this.changeLayout(item.name)}><TiTick className={style.tickIc}/><p className={style.subTit}>{item.name}</p></div>
         
         )
        })
        return (
          <>
           <div className={style.contNav}>

</div>
            <div className={style.container}>
             
                    {/*<h1 className={style.tit}>Portofolio</h1>*/}
                    {/*<div className={style.artListDiv}>
                      {artistsList}
                    </div>*/}
                    
                    
                <div className={style.contHead}>
                   
                    <div className={style.contHeadTitles}>

                         <div className={style.div1}>
                             <div className={style.artImgDiv} id={style.artImgDiv1}>
                             <img src={theImage}/>     
                             </div>
                             <div className={style.artImgDiv} id={style.artImgDiv2}>
                             <img src={theImage2}/>     
                             </div>
                             <div className={style.artDetDiv}>
                        <h1>SERONEY</h1>
                        <p>Seroney is an afro pop singer, song writer, record producer and film maker.</p>
                        <p>Raised in the poorer neighbourhoods of Machakos town, surrounded by poverty and crime, music was his escape.</p>
                        <p>As a teenager, Seroney would record the international radio show “Rick Dees Weekly Top 40” on his mother’s tapes, which he would later listen to over and over. The show covered topics such as current hits and music business.</p>
                        {/*<p>The teenage Seroney also loved reading the “pop stop” weekly newspaper article published on the “Daily Nation” which contained lyrics of most popular pop songs.  These articles stirred his love for RnB and inspired his song writing skills.  He would hang a lot in the nearby hood-mjini which was a small Swahili Kamba community but full of vibes, sports, movies, and music whenever he went out to play.</p>*/}
                        <div className={style.divEarly1}>
                        <h2>Early Life</h2>
                        <p>Seroney was born Born in Nairobi, Kenya on February 25, 1988 he has two younger sisters.</p>
                        <p>It was while in high school at Kathiani boys’ high school that Seroney fell in love with dancehall music. He identified with artists such as Kelvin Little, Wayne Wonder, Voicemail and Rupee who had an RnB sound background like him.</p> 
                        </div>    </div>
                         </div>
                         <div className={style.who1Div}>
                         <h1>SERONEY?</h1>
                         <p>Raised in the poorer neighbourhoods of Machakos town, surrounded by poverty and crime, music was his escape.</p>
                        <p>As a teenager, Seroney would record the international radio show “Rick Dees Weekly Top 40” on his mother’s tapes, which he would later listen to over and over. The show covered topics such as current hits and music business.</p>
                         </div>
                         <div className={style.divEarly2}>
                        <h2>Early Life</h2>
                        <p>Seroney was born Born in Nairobi, Kenya on February 25, 1988 he has two younger sisters.</p>
                        <p>It was while in high school at Kathiani boys’ high school that Seroney fell in love with dancehall music. He identified with artists such as Kelvin Little, Wayne Wonder, Voicemail and Rupee who had an RnB sound background like him.</p> 
                        </div>
                        <h2>Music career</h2>
                        <p>Seroney began his musical career as a gospel artist in Kenya recording music at Lampstand records. His single “Falling” ft Rufftone was a huge hit, topping the gospel charts and earning him a Groove Awards nomination. </p>
                        <p>Seroney took a break from music to attend Moi University School of Law from where he graduated with a Bachelor of Laws degree in 2012 and later joined the Kenya School of Law for his Bar Course. Despite studying law, Seroney’s passion for music remained and during his studies he continued writing and recording music.</p>
                        <p>He released his first secular single “Shuga” in 2015 and it was a massive hit. The Music video was shot in Mjini – Machakos, the same hood he grew up in. The single gained international recognition in USA, Canada, and Europe and was featured at the France Pure Charts.</p>
                        <p>Seroney’s other singles followed; Sweet potato (2015), Friday ft Madtraxx (2016), Delilah (2016), Party feat Kenyan American Based Singer Naomi Wamboe (2018), Chini ya Waba (2019) and Washa kigwai (2019). </p>
                        <p>Seroney’s debut Album “Am A Star”, released under Universal Music Group Kenya in November 2022 features massive hits “Summertime” “Nobody” “Nakutamani” “Wiggle” and the album title track “Am A Star”.  The Album is a blend of Swahili music, dancehall music and Rnb. </p>
                       
                      
                    </div>
                </div>
                <div className={style.galleryDiv}>
                 <div  className={style.galDiv}>
                 <h2  style={videosCont} onClick={(event)=>this.divToShow(event,'videos')}>VIDEOS</h2>
                 <h2  style={albumsCont} onClick={(event)=>this.divToShow(event, 'albums')}>ALBUMS</h2>
                 </div>
                 {divToShow}
                </div>
        
            </div></>
        )
    }
}

export default ArtistsHome

import React, { Component } from 'react'
import style from './Home7.module.scss';
import {NavLink} from 'react-router-dom'

class Home7 extends Component {
    render() {
        return (
            <div className={style.container}>
                <h1>NEW MUSIC ON THE WAY</h1>
                {/*<NavLink to='/audio' className='ulLink'><p>SEE MORE</p></NavLink>*/}
                <NavLink to='/music' className='ulLink'><p>SEE MORE</p></NavLink>
               
            </div>
        )
    }
}

export default Home7

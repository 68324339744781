import React, { Component } from 'react'
import style from './Home4.module.scss';//FaPlay
import {FaHeadphones} from 'react-icons/fa';
import {MdClose} from 'react-icons/md';
import firebase from 'firebase'
import {Link, NavLink, withRouter} from 'react-router-dom'
const musicPltaforms=[
    {id:1, title:'Boomplay'},
    {id:2, title:'Spotify'},
    {id:3, title:'Amazon'},
    {id:4, title:'I-Tunes'},
]
const theSongs=[
  {id:1, title:'Summertime'},
  {id:2, title:"Party Don't Stop"},
  {id:3, title:'Wiggle'},
  {id:4, title:'Nakutamani'},
  {id:5, title:'Fiesta'},
  {id:6, title:"Whine It"},
  {id:7, title:"Nobody"},
  {id:8, title:'Pick Up The Phone'},
  {id:9, title:'Banana'},
  {id:10,title:'Am A Star'},
]

 class Home4 extends Component {
     state={
        musicArray:[],
        releaseStatus:false,
        songTitle:''
     }
     componentDidMount(){
         this.fetchAlbum()
     }
    fetchAlbum=async()=>{
        try {
          var newArray=[]
          var newArray2 = []
          var theSongName="song-name"
          var musicRef = firebase.database().ref('/Albums/AfricanPopstar');
    
          await musicRef.once('value', (snapshot) => {
            snapshot.forEach((data) => {
             var id = data.key
             var artist= data.val().name
             var duration= data.val().duration
             var music= data.val().audio
             var songname= data.val().title
             var released= data.val().released
             //console.log('artist', artist)
             newArray={
                artist:artist, duration:duration, music:music, songname:songname, id:id, released:released
                  }
                  newArray2.push(newArray)
                  })
                  this.setState({
                    musicArray:newArray2
            })
           })
         // console.log('musicArray', this.state.musicArray)
        } catch (error) {
          
        }
      }
      playAlbumSong=(musicInfo, released,title)=>{
          if (released===false) {
              this.setState({
                releaseStatus:true,
                songTitle:title  
              })
            return
          }
        this.props.history.push({
            pathname: '/albums',
            state:musicInfo
    }); 
      }
      closeSnack=()=>{
        this.setState({
            releaseStatus:false  
          })
      }
    render() {
        const musicList=theSongs.map((item,index)=>{
            let theSongName=(index+1)+'. '+item.title
            let musicInfo=item.songname+'##'+item.artist+'##'+item.duration+'##'+item.music+'##'+item.id
            let released =item.released
            let title =item.title
            return(
                <div className={style.listCont} key={index}>
                 
            <h1>{theSongName}</h1>
              
               <div className={style.listContTime}>
               <h1>{item.duration}</h1>
               <a style={{color:'#000', marginTop:0}} target="_blank"  href='https://umgke.lnk.to/AmAStar'> <FaHeadphones className={style.playIcon}/></a>
              
                   </div>
               </div>
            )
        })
        const platformsList=musicPltaforms.map((item,index)=>{
            return(
                <div className={style.platList} key={index}>
                 <p>{item.title}</p>
               </div>
            )
        })
        return (
            <div className={style.container}>
                   <div className={style.container1}>
                    <div className={style.albumWorkDiv}>
                    <img className={style.artImg} src={'./pic12.jpg'}></img>
                    </div>
                    <h2>'AM A STAR' ALBUM</h2>
                    <p>'AM A STAR' is an Album by Seroney. AM A STAR 
Album is an all-collaboration project with ten tracks including Summertime,
Party Don't Stop, Wiggle Featuring Masauti & Exray, Nakutamani,
Fiesta Featuring Kidis, Whine It, Nobody, 
Pick Up The Phone Featuring Drill Kawasaki
Banana Featuring Bryon & Drill Kawasaki and Am A Star</p>
                </div>
                <div className={style.container2}>
                    {
                this.state.releaseStatus?
                <div className={style.notReleasedSnack}>
                <MdClose className={style.closeRelease} onClick={()=>this.closeSnack()}/>
                <h3 className={style.songTitle}>{this.state.songTitle}</h3>
                <h4>Available on the Platforms below</h4>
                <div className={style.platCont}>
                    {platformsList}
                </div>
               </div>:null}
                {musicList}
                </div>

               
            </div>
        )
    }
}

export default withRouter (Home4)

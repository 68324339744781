import React, { Component } from 'react'
import style from './Footer.module.scss';
import {FaFacebookF,FaTwitter,FaYoutube,FaSnapchatGhost} from 'react-icons/fa';
import {AiFillInstagram} from 'react-icons/ai';
import {SiTiktok} from 'react-icons/si';

 class Footer extends Component {
    render() {
        return (
            <div className={style.contFooter}>
               <h1>SERONEYMUSIC</h1>
               <div className={style.contFooter2}>
               <a target="_blank"  href='https://www.facebook.com/seroneymusic'>
                        <FaFacebookF className={style.footerIcons}/></a>
                        <a target="_blank" rel="noreferrer" href='https://twitter.com/iam_seroney'>
                       <FaTwitter className={style.footerIcons}/></a>
                       <a target="_blank" rel="noreferrer" href='https://www.instagram.com/iam_seroney/'>
                       <AiFillInstagram className={style.footerIcons}/></a>
                       <a target="_blank" rel="noreferrer" href='https://www.youtube.com/channel/UCgSiMxux4-_gW8medZcmIHQ'>
                       <FaYoutube className={style.footerIcons}/></a>
                       <a target="_blank" rel="noreferrer" href='https://www.tiktok.com/@seroney'>
                       <SiTiktok className={style.footerIcons}/></a>
               </div>
               <h3>COPYRIGHT SERONEYMUSIC 2023</h3>
               <p style={{fontSize:10,marginTop:5}}>Developed by Clemo 0724998745</p>
<a target="_blank" href="https://www.wakenya.co.ke/" style={{textDecoration:'none'}}><p style={{color:'#000',fontSize:10,display:'flex',alignItems:'flex-end'}}>buy and sell in kenya from huruapp.co.ke</p></a>
            </div>
        )
    }
}

export default Footer

import { POST_PROJECT, SAVE_POSTS,SAVE_PROJECT_POSTS,SAVE_LOGIN_STATUS,SHOW_LOGIN_MODAL,SAVE_CATEGORIES,CURRENT_USER_INFO} from './Types'

const initState = {
  post: [],
  savedposts: [],
  savedProjectPosts:[],
  savedLogInStatus:'',
  savedLogInModal:'',
  savedCategories:'',
  savedUserInfo:''
}

const projectReducer = (state = initState, action) => {
  console.log(action.type)
  switch (action.type) {
    case POST_PROJECT:
      return {
        ...state,
        post: action.post
      };

    case SAVE_POSTS:
      return {
        ...state,
        savedposts: action.savedposts
        
      };
      case SAVE_PROJECT_POSTS:
        return{
          ...state,
          savedProjectPosts:action.savedProjectPosts
        };
        case SAVE_LOGIN_STATUS:
         return{
         
           ...state,
           savedLogInStatus:action.savedLogInStatus
         
        }
        case SHOW_LOGIN_MODAL:
         return{
         
           ...state,
           savedLogInModal:action.savedLogInModal
         
        }
        case SAVE_CATEGORIES:
          return{
            ...state,
            savedCategories:action.savedCategories
          
         }
         case CURRENT_USER_INFO:
          return{
            ...state,
            savedUserInfo:action.savedUserInfo
          
         }

    default:
      
      return state;
  }
};

export default projectReducer

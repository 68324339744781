import React, { Component } from 'react'
import style from './BookMe.module.scss';
import Footer from '../Footer/Footer'
import {FaFacebookF,FaTwitter,FaYoutube,FaSnapchatGhost} from 'react-icons/fa';
import {AiFillInstagram} from 'react-icons/ai';
import {SiTiktok} from 'react-icons/si';

 class BookMe extends Component {
    componentDidMount=async()=>{
        window.scrollTo(0, 0)}
    render() {
        return (
            <div className={style.container}>
                 <div className={style.contNav}> 
                   </div>
                   <div>
                   <h5 className={style.contTitle}>BOOK ME</h5>
                   <div className={style.container0}>
                   <div className={style.container2}>
                       <h1>BOOK SERONEY</h1>
                       <p>Do you have an occasion, birthday, wedding, club, or any other event and are in need of an artist?. Book Seroney to come and grace your event by getting in contact with his team using the official Phone No and email indicated below. For any other queries please dont hesitate to contact us using the same contact details.</p>
                       <h4>TEL NO: +254 721 179 382</h4>
                       <h4>EMAIL: bookings@seroneymusic.com</h4>

                       <h3>SOCIAL MEDIA:</h3>
                       <div className={style.socialCont}>                          
                       <a target="_blank"  href='https://www.facebook.com/seroneymusic'>
                        <FaFacebookF className={style.footerIcons}/></a>
                        <a target="_blank" rel="noreferrer" href='https://twitter.com/iam_seroney'>
                       <FaTwitter className={style.footerIcons}/></a>
                       <a target="_blank" rel="noreferrer" href='https://www.instagram.com/iam_seroney/'>
                       <AiFillInstagram className={style.footerIcons}/></a>
                       <a target="_blank" rel="noreferrer" href='https://www.youtube.com/channel/UCgSiMxux4-_gW8medZcmIHQ'>
                       <FaYoutube className={style.footerIcons}/></a>
                       <a target="_blank" rel="noreferrer" href='https://www.tiktok.com/@seroney'>
                       <SiTiktok className={style.footerIcons}/></a>
                       </div>
                   </div>
                   <div className={style.container3}>
                       <h6>WRITE YOUR MESSAGE BELOW</h6>
                   <div className={style.contName}>
                   <input placeholder='Name'/>
                   <input placeholder='Email'/>
                   </div>
                   <input placeholder='Phone No' className={style.contSub}/>
                   <input placeholder='Subject' className={style.contSub}/>
                   <div className={style.bookingContMeso}>
                   <textarea className={style.messageInput} type='text' id='message' placeholder='Write your message here'></textarea>
                   </div>
                   
                   {/*<input placeholder='Message' className={style.contSub}/>*/}
                   <h5>
                       SEND MESSAGE
                   </h5>
                  
                   </div>
                   </div>
               <Footer/>
               </div>
            </div>
        )
    }
}

export default BookMe
